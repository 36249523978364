export enum TradesClassificationTypes {
    // General Construction and Contracting
    GENERAL_CONTRACTING = "General Contracting",
    REMODELING = "Remodeling",
    CUSTOM_HOME_BUILDING = "Custom Home Building",
    BUILDING_MAINTENANCE = "Building Maintenance",
    DEMOLITION = "Demolition",
    
    // Electrical and Related Trades
    ELECTRICAL = "Electrical",
    LOW_VOLTAGE_ELECTRICAL = "Low Voltage Electrical",
    SOLAR_PANEL_INSTALLATION = "Solar Panel Installation",
    GENERATOR_INSTALLATION = "Generator Installation",
    SMART_HOME_SETUP = "Smart Home Setup",
    SECURITY_SYSTEMS = "Security Systems",
    FIRE_ALARM_INSTALLATION = "Fire Alarm Installation",
  
    // HVAC and Plumbing
    HVAC = "HVAC",
    PLUMBING = "Plumbing",
    BOILER_SERVICES = "Boiler Services",
    GAS_LINE_INSTALLATION = "Gas Line Installation",
    SEPTIC_SERVICES = "Septic Services",
    WATER_HEATER_INSTALLATION = "Water Heater Installation",
  
    // Carpentry and Structural Work
    CARPENTRY = "Carpentry",
    FRAMEWORK = "Framework",
    FURNITURE_BUILDING = "Furniture Building",
    CUSTOM_CABINETRY = "Custom Cabinetry",
    DECK_BUILDING = "Deck Building",
    FENCING = "Fencing",
    ROOFING = "Roofing",
    GUTTER_INSTALLATION = "Gutter Installation",
  
    // Masonry and Concrete
    MASONRY = "Masonry",
    CONCRETE_WORK = "Concrete Work",
    ASPHALT_PAVING = "Asphalt Paving",
    BRICKLAYING = "Bricklaying",
    STONEWORK = "Stonework",
    TILE_LAYING = "Tile Laying",
  
    // Painting, Coatings, and Finishing
    PAINTING = "Painting",
    WALLPAPER_INSTALLATION = "Wallpaper Installation",
    EPOXY_FLOORING = "Epoxy Flooring",
    WOOD_STAINING = "Wood Staining",
  
    // Landscaping and Outdoor Work
    LANDSCAPING = "Landscaping",
    IRRIGATION_SYSTEMS = "Irrigation Systems",
    TREE_SERVICES = "Tree Services",
    POOL_SERVICES = "Pool Services",
    EXTERIOR_CLEANING = "Exterior Cleaning",
    SNOW_REMOVAL = "Snow Removal",
  
    // Mechanical and Industrial
    WELDING = "Welding",
    HEAVY_EQUIPMENT_REPAIR = "Heavy Equipment Repair",
    MECHANICAL_ENGINEERING = "Mechanical Engineering",
    PIPEFITTING = "Pipefitting",
    SHEET_METAL_WORK = "Sheet Metal Work",
  
    // Flooring and Interiors
    FLOORING = "Flooring",
    CARPET_INSTALLATION = "Carpet Installation",
    HARDWOOD_FLOORING = "Hardwood Flooring",
    VINYL_FLOORING = "Vinyl Flooring",
    INSULATION = "Insulation",
    DRYWALL = "Drywall",
    CEILING_WORK = "Ceiling Work",
  
    // Appliances and Systems
    APPLIANCE_REPAIR = "Appliance Repair",
    HVAC_REPAIR = "HVAC Repair",
    SMART_APPLIANCE_SETUP = "Smart Appliance Setup",
    PEST_CONTROL = "Pest Control",
    HOME_INSPECTION = "Home Inspection",
    
    // Specialty and Emerging Fields
    GREEN_BUILDING = "Green Building",
    ENERGY_EFFICIENCY = "Energy Efficiency",
    WIND_TURBINE_INSTALLATION = "Wind Turbine Installation",
    EV_CHARGER_INSTALLATION = "EV Charger Installation",
    AQUAPONICS = "Aquaponics",
    HYDROPONICS = "Hydroponics",

    OTHER = "Other"
}
  