import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import imageCompression from 'browser-image-compression';

import { NavigatorService, keyTypesEnum } from './navigator.service';

// AWS
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { el } from 'date-fns/locale';
import { LinkedAccount } from 'src/app/data-models/models';


@Injectable({
    providedIn: 'root'
})

export class ImageHandlerService {
    private returnedImageSubject = new Subject<any>();
    private returnedImagesSubject = new Subject<any>();

    private accessKeyId:string = environment?.awsS3AccessKey;
    private secretAccessKey:string = environment?.awsS3SecretKey;

    private region:string = 'us-east-2';

    private keyTypes:any = keyTypesEnum;
    private returnedFile: any = null;


    constructor(public http:HttpClient, public realm:MongoService, public router:Router, private navigatorService: NavigatorService, private sanitizer: DomSanitizer) { 

    }


    async uploadFile(file: File, fileId: string, folder?: string): Promise<void> {
        await this.compressImage(file).then(async compressedImg => {
            if(compressedImg) {

                if(folder == undefined || folder == null || folder.length == 0) {
                    folder = this.navigatorService.getCompanyId();

                    if(folder == undefined || folder == null || folder.length == 0) {
                        folder = this.navigatorService.getProfileId();
                    }
                }

                await this.uploadToServer(compressedImg, fileId, folder);
            }
        });

        console.log("Image Upload Return");
    }

    private async compressImage(file: File): Promise<File> {

        if(!file.type.includes('image')) {
            return file;
        }

        const imageFile = file;
        console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }

        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

          return compressedFile

        } catch (error) {
          console.log(error);
        }
      
    }

    private async uploadToServer(file: File, fileId: string, folder?: string) {

        if(folder == undefined || folder.length == 0) {
            folder = this.navigatorService.getProfileId();
        }

        const fileName = fileId;
        const contentType = file.type;


        const bucket = new S3(
            {
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
                region: this.region
            }
        );

        const params = {
            Bucket: environment?.awsS3Bucket,
            Key: folder + '/' + fileName,
            Body: file,
            ACL: 'private',
            ContentType: contentType
        };

        await bucket.upload(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }

            console.log('Successfully uploaded file.', data);
            return true;
        }).promise();

        // await bucket.getObject(params, function (err, data) {
        //     if (err) {
        //         console.log('There was an error reading your file: ', err);
        //         return false;
        //     }

        //     console.log('Successfully read file.', data);
        //     return true;
        // }).promise();

        //for upload progress   
        /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
    }


    async getAsyncFiles(fileIds: Array<string> | Array<LinkedAccount>, folder?: string, isLinkedAccounts?: boolean): Promise<Array<SafeUrl>> {
        let files: Array<SafeUrl> = [];

        let folderID: string = null;
        
        if(folder == undefined || folder == null || folder.length <= 0) {
            folderID = this.navigatorService.getCompanyId();
        } else {
            folderID = folder;
        }

        if(folderID == undefined || folderID == null || folderID.length == 0) {
            folderID = this.navigatorService.getProfileId();
        }
        
        await this.getFilesFromServer(fileIds, [], folderID, isLinkedAccounts).then(data => {

            if(data != null && data != undefined) {
                files = data;
            }

        });

        console.log("Async Images: ", files);

        return files;
    }

    async getAsyncFile(fileId: string): Promise<SafeUrl> {
        let file: SafeUrl = '';

        let folderID: string = this.navigatorService.getCompanyId();

        if(folderID == undefined || folderID == null || folderID.length == 0) {
            folderID = this.navigatorService.getProfileId();
        }
        
        await this.getAsyncFileFromServer(fileId, folderID).then(data => {

            if(data != null && data != undefined) {
                file = data;
            }

        });

        console.log("Async Images: ", file);

        return file;
    }

    getFiles(fileIds: Array<string>): Observable<any> {
        let folderID: string = this.navigatorService.getCompanyId();

        if(folderID == undefined || folderID == null || folderID.length == 0) {
            folderID = this.navigatorService.getProfileId();
        }

        this.getFilesFromServer(fileIds, [], folderID);

        return this.returnedImagesSubject.asObservable();
    }

    getFile(fileId: string): Observable<any> {
        let folderID: string = this.navigatorService.getCompanyId();

        if(folderID == undefined || folderID == null || folderID.length == 0) {
            folderID = this.navigatorService.getProfileId();
        }

        this.getFileFromServer(fileId, folderID);

        return this.returnedImageSubject.asObservable();
    }

    private async getFileFromServer(fileId: string, companyId?: string): Promise<void> {
        let folder = this.navigatorService.getCompanyId();
        const fileName = fileId;
        let self = this;

        let safeUrl: SafeUrl = null;

        if(companyId != undefined) {
            folder = companyId;
        }

        const bucket = new S3(
            {
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
                region: this.region
            }
        );

        const params = {
            Bucket: environment?.awsS3Bucket,
            Key: folder + '/' + fileName
        };

        await bucket.getObject(params).promise().then(image => {

            if(image != null && image != undefined) {
                console.log('Successfully read file.', image);

                let imageBlob = this.convertToImageBlob(image);
                let sanitizedUrl: any = null;

                if(imageBlob) {
                    sanitizedUrl = this.sanitizeImageURL(imageBlob);     
                }

                
                safeUrl = sanitizedUrl;
            } else {
                safeUrl = "Failed";
            }

            self.returnedImageSubject.next(safeUrl);

            // if (err) {
            //     console.log('There was an error reading your file: ', err);
            //     return false;
            // }

            // console.log('Successfully read file.', data);

            // let imageBlob = self.convertToImageBlob(data);
            // let sanitizedUrl = self.sanitizeImageURL(imageBlob);

            // self.returnedImageSubject.next(sanitizedUrl);
            // safeUrl = sanitizedUrl;
        });

        //for upload progress   
        /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
    }

    private async getAsyncFileFromServer(fileId: string, companyId?: string): Promise<SafeUrl> {
        let folder = this.navigatorService.getCompanyId();
        const fileName = fileId;
        let self = this;

        let safeUrl: SafeUrl = null;

        if(companyId != undefined) {
            folder = companyId;
        }

        const bucket = new S3(
            {
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
                region: this.region
            }
        );

        const params = {
            Bucket: environment?.awsS3Bucket,
            Key: folder + '/' + fileName
        };

        await bucket.getObject(params).promise().then(image => {

            if(image != null && image != undefined) {
                console.log('Successfully read file.', image);

                let imageBlob = this.convertToImageBlob(image);
                let sanitizedUrl: any = null;

                if(imageBlob) {
                    sanitizedUrl = this.sanitizeImageURL(imageBlob);     
                }

                safeUrl = sanitizedUrl;
            } else {
                safeUrl = "Failed";
            }

            // if (err) {
            //     console.log('There was an error reading your file: ', err);
            //     return false;
            // }

            // console.log('Successfully read file.', data);

            // let imageBlob = self.convertToImageBlob(data);
            // let sanitizedUrl = self.sanitizeImageURL(imageBlob);

            // self.returnedImageSubject.next(sanitizedUrl);
            // safeUrl = sanitizedUrl;
        });

        return safeUrl;

        //for upload progress   
        /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
    }

    private async getFilesFromServer(fileIds: Array<any>, returnedFiles: Array<SafeUrl>, companyId?: string, isLinkedAccounts?: boolean): Promise<Array<SafeUrl>> {

        let folder = this.navigatorService.getCompanyId();

        if(companyId != undefined) {
            folder = companyId;
        }

        const bucket = new S3 (
            {
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.secretAccessKey,
                region: this.region
            }
        );

        let params = {
            Bucket: environment?.awsS3Bucket,
            Key: ""
        };

        for(let fileId of fileIds) {

            if(isLinkedAccounts) {
                folder = fileId.id;
                fileId = fileId.profileImg;
            }

            if(fileId != null && fileId.length > 0 && fileId != "Empty Image ID") {
                
                params.Key = folder + '/' + fileId;

                if(isLinkedAccounts) {
                    console.log("Obtaining Linked Account Folder: ", folder);
                    console.log("Obtaining Linked Account ID: ", fileId);
                }

                try {
                await bucket.getObject(params).promise().then(image => {
                
                    if(isLinkedAccounts) {
                        console.log("Obtaining Linked Account Img: ", image);
                    }

                    if(image != null && image != undefined) {
                        console.log('Successfully read file.', image);

                        let imageBlob = this.convertToImageBlob(image);
                        let sanitizedUrl: any = null;

                        if(imageBlob) {
                            sanitizedUrl = this.sanitizeImageURL(imageBlob);     
                        }

                        returnedFiles.push(sanitizedUrl);
                    } else {
                        returnedFiles.push("Failed");
                    }
                });
                } catch (error) {
                    console.log("Failed to pull image: ", error);
                    returnedFiles.push("Failed");
                }
            } else {
                returnedFiles.push("Failed");
            }

            // this.returnedImagesSubject.next(returnedFiles);  
        }

        return returnedFiles;

        //for upload progress   
        /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
    }

    private convertToImageBlob(image) {
        
        return URL.createObjectURL( new Blob([image.Body], {type: image.ContentType } ));

        

        // if(image != undefined && image != null && image.Body) {
        //     return URL.createObjectURL( new Blob([image.Body], {type: image.ContentType } ));
        // } else {
        //     return "";
        // }
    }

    private sanitizeImageURL(imageURL:string): SafeUrl {
        let sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(imageURL);

        return sanitizedUrl;
    }

}